import React from 'react'
import Page from '../components/Page'

const MonitoringPage = () => (
  <Page title="Monitoring" image="/img/106.jpg">
    <h1 className="text-2xl text-center w-full mt-8">MONITORING COMPETITOR'S IP</h1>
      <p className="mt-8 w-2/3 text-center">
      You want to know your CN competitor's IP, research its vulnerability in patent prosecution or legal proceedings?<br/>
      <br/>
      Ask us to inspect it, translate or comment it.<br/>
      </p>
  </Page>
)

export default MonitoringPage